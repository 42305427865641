/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-15T09:08:97+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';
import classNames from 'classnames';

import './HeaderSectionItem.css';

const cls = suffix => (suffix ? `txo-headerSectionItem-${suffix}` : `txo-headerSectionItem`);

export const HeaderSectionItem = ({heading, children, className}) => (
  <div className={classNames(cls(), className)}>
    <div className={cls('heading')}>
      {heading}
      <span className="red-text">.</span>
    </div>
    <div className={cls('text')}>
      {children}
    </div>
  </div>
);