/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-15T14:08:36+02:00
 * @Copyright: Technology Studio
 * @flow
**/
import { Translation } from 'react-i18next';

import React from 'react';
import BannerAnim, { Element } from 'rc-banner-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { Button } from 'components/Button';
import './BannerAnimation.css';

import computer from '../../images/computer.png';
import mobil from '../../images/mobil.png';
import backend from '../../images/backend.png';
const BgElement = Element.BgElement;

const cls = suffix =>
  suffix ? `txo-bannerAnimation-${suffix}` : `txo-bannerAnimation`;
    
export const BannerAnimation = () => (
  <Translation>
    {(t, {i18n}) => {
      return (
        <BannerAnim 
          autoPlay
          type="across"
          duration={3000}
          delay={3000}
        >
          <Element key="react"
            prefixCls={cls('element')}
          >
            <BgElement
              key="bg"
              className={cls('slide-one')}
            />
            <QueueAnim
              duration={3000}
              interval={500}
              name="QueueAnim" 
              className={cls('text-block-queueAnim')}
            >
              <h1 
                className={cls('header-title')} 
                key="h1"
              >
            React
                <span 
                  className="red-text"
                >
            .
                </span>
              </h1>
              <h2 
                className={cls('header-subtitle')}
                key="h2"
              >
                {t('main.annimation.Single_page_application_DOT')}
              </h2>
              <Button
                key="button"
                colorVariant="colored"
                href="#services"
                className={cls('button')}
              >
                {t('button.services')}
              </Button>
            </QueueAnim>
            <TweenOne 
              animation={{ 
                duration: 2000,
                x: 1000,
                opacity: 0, 
                type: 'from', 
                delay: 1000,
              }} 
              name="TweenOne"  
              className={cls('text-block-tweenOne')}
            >
              <img 
                className={cls('toy')}
                src={computer}
              />
            </TweenOne>
          </Element>
          <Element key="react-native"
            prefixCls={cls('element')}
          >
            <BgElement
              key="bg"
              className="txo-bannerAnimation-slide-one slide-three"
            />
            <QueueAnim
              duration={3000}
              interval={500}
              name="QueueAnim" 
              className={cls('text-block-queueAnim')}
            >
              <h1 
                className={cls('header-title')} 
                key="h1">React Native
                <span 
                  className="red-text"
                >
            .
                </span>
              </h1>
              <h2 
                className={cls('header-subtitle')}
                key="h2"
              >
                {t('main.annimation.Mobile_Native_Application')}
              </h2>
              <Button
                key="button"
                colorVariant="colored"
                href="#services"
                className={cls('button')}
              >
                {t('button.services')}
              </Button>
            </QueueAnim>
            <TweenOne 
              animation={{ 
                duration: 1500,
                x: 400,
                opacity: 0, 
                type: 'from', 
                delay: 1000,
              }} 
              name="TweenOne"  
              className={cls('text-block-tweenOne-mobil')}
            >
              <img 
                className={cls('mobil')}
                src={mobil}/>
            </TweenOne>
          </Element>
          <Element key="graphql"
            prefixCls={cls('element')}
          >
            <BgElement
              key="bg"
              className="txo-bannerAnimation-slide-one slide-two"
            />
            <QueueAnim
              duration={3000}
              interval={500}
              name="QueueAnim" 
              className={cls('text-block-queueAnim-two')}
            >
              <h1 
                className={cls('header-title')} 
                key="h1"
              >
            GraphQL
                <span 
                  className="red-text"
                >
            .
                </span>
              </h1>
              <h2 
                className={cls('header-subtitle')}
                key="h2"
              >
                {t('main.annimation.Fully_Featured_GraphQL')}
              </h2>
              <Button
                key="button"
                colorVariant="colored"
                href="#services"
                className={cls('button')}
              >
                {t('button.services')}
              </Button>
            </QueueAnim>
            <TweenOne 
              animation={{ 
                duration: 2000,
                x: -1400,
                opacity: 0, 
                type: 'from', 
                delay: 1000,
              }} 
              name="TweenOne"  
              className={cls('text-block-tweenOne-backend')}
            >
              <img 
                className={cls('backend')}
                src={backend}
              />
            </TweenOne>
          </Element>
        </BannerAnim>
      );
    }}
  </Translation>
);