/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-09T13:08:92+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import {Link} from 'gatsby-plugin-modern-react-i18next';
import React from 'react';

import './HeaderLink.css';

type Props = {
  title: string,
  to: string,
};

const cls = suffix => suffix ? `txo-header-link-${suffix}` : `txo-header-link`;

export const HeaderLink = ({title, to}: Props) => (
  <Link className={cls()} to={to}>
    {title}
  </Link>
);