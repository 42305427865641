/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-07-31T19:07:35+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import { Switcher } from './Switcher';

export { Switcher };
