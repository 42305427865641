/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-09T13:08:11+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import { Translation } from 'react-i18next';

import { HeaderLink } from './HeaderLink';
import { Switcher }  from '../Switcher';
import { Link } from 'gatsby-plugin-modern-react-i18next';
import React from 'react';
import Headroom from 'react-headroom';

import './Header.css';
const cls = suffix => (suffix ? `txo-header-${suffix}` : `txo-header`);

export const Header = ({location}: {location: Location}) => (
  <Translation>
    {(t, {i18n}) => {

      
      return (
        <header className={cls()}>
          <Headroom disableInlineStyles={true}>
            <div className={cls('section')}>
              <Link 
                className={cls('logo-link')} 
                to="/"
              >
                ReactFISH
              </Link>
              <nav className={cls('nav-bar')}>
                <HeaderLink
                  title={t('header.action.HOME')}
                  to="/"
                />
                <HeaderLink
                  title={t('header.action.SERVICES')}
                  to="#services"
                />
                <HeaderLink
                  title={t('header.action.CONTACT')}
                  to="#contact"
                />
              </nav>
              <nav className={cls('language')}>
                <Switcher />
              </nav>
            </div>
          </Headroom>
        </header>
      );
    }}
  </Translation>
);