/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-15T09:08:97+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';
import { Link } from 'gatsby-plugin-modern-react-i18next';

import './IconListItem.css';

type Props = {
  +children: any,
  to?: string,
  +icon: string,
};

const cls = suffix => (suffix ? `txo-icon-bullet-${suffix}` : `icon-bullet`);

export const IconListItem = ({children, to, icon} : Props) => (
  <div className={cls('icon-list')}>
    <i className={icon} />
    {to 
      ? <Link to={to}>{children}</Link>
      : <span>{children}</span>
    }
  </div>
);
