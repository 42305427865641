/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2020-01-20T18:01:59+01:00
 * @Copyright: Technology Studio
 * @flow
**/

import React from 'react';
import { Language } from 'gatsby-plugin-modern-react-i18next';

import './Switcher.css';

const cls = suffix => suffix ? `txo-switcher-${suffix}` : `txo-switcher`;

type Props = { 
// changeLng,
// lng,
// availableLngs,
}


// $FlowFixMe
const SwitcherPure = (props: Props) => {
  const { changeLng, lng, availableLngs } = props;
  return (
    <div className={cls()}>
      {availableLngs && availableLngs.map(value => (
        <div 
          key={value}
          className={
            value === lng ? cls('active-lang') : cls()
          }
          onClick={() => changeLng(value)}
        >
          {value}
        </div>
      ))}
    </div>
  );
};

export const Switcher = () => (
  <Language>{lngProps => <SwitcherPure {...lngProps} />}</Language>
);