/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-08T14:08:18+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import patchDOMForGoogleTranslate from 'utils/patchDOMForGoogleTranslate';
import React, { Component } from 'react';
import { Footer } from 'components/LayoutFooter';
import { Header } from 'components/LayoutHeader';
import { BannerAnimation } from 'components/BannerAnimation';
import './Layout.css';

patchDOMForGoogleTranslate();

type Props = {
  children: Function,
  location: Location,
};

class Template extends Component<Props> {
  render() {
    const {children, location} = this.props;

    return (
      <div className="txo-layout">
        <Header location={location} />
        <div>
          <BannerAnimation/>
        </div>
        {children}
        <Footer/>
      </div>
    );
  }
}

export default Template;
