/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-07-24T14:07:27+02:00
 * @Copyright: Technology Studio
 * @flow
 **/
import { Translation } from 'react-i18next';

import React from 'react';
import { Link } from 'gatsby-plugin-modern-react-i18next';
import { HeaderSectionItem } from 'components/HeaderSectionItem';
import { Button } from 'components/Button';
import { IconListItem } from './IconListItem';

import './Footer.css';

const cls = suffix => suffix ? `txo-footer-${suffix}` : `txo-footer`;

export const Footer = () => (
  <Translation>
    {(t, {i18n}) => (
      <div>
        <div 
          id="contact" 
          className={cls()}
        >
          <div className={cls('contact')}>
            <HeaderSectionItem 
              className={cls('header')}
              heading={t('footer_Contact_US')}
            >
              {t('footer_We_will_be_pleased_to_help_you_with_your_project_COMMA_suggest_realization_and_specify_the_price_DOT')}
            </HeaderSectionItem>
            <Button 
              className={cls('button')} 
              colorVariant="colored"
              href="mailto:contact@react.fish"
            >
            
              {t('button.CONTACT_US')}
            </Button>
          </div>
          <div className={cls('company')}>
            <div className={cls('company-detail')}>
              <p>ReactFISH, s.r.o.</p>
            </div>
            <div className={cls('contact-details')}>
              {/* <IconListItem icon="icon_phone">
              +421 918 480 279
          </IconListItem>    */}
              <IconListItem icon="icon_comment">
                <a 
                  className={cls('link-color')} 
                  href="mailto:contact@react.fish"
                >
                contact@react.fish
                </a>
              </IconListItem>   
              <IconListItem icon="icon_pin">
                Galvaniho 16407/7D, 82104 Bratislava – Ružinov
              </IconListItem>   
              <IconListItem icon="icon_shield_alt">
                <Link 
                  className={cls('link-color')} 
                  to="../../../content/docs/privacy"
                >
                  {t('footer_Privacy_Policy')}
                </Link>
              </IconListItem>   
            </div>
          </div>
        </div>
        <div className={cls('copyright')}>
          {`Copyright © ${new Date().getFullYear()} ReactFISH, s.r.o.`}
        </div>
      </div>
    )}
  </Translation>
);