/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-07-24T14:07:27+02:00
 * @Copyright: Technology Studio
 * @flow
 **/

import React from 'react';
import classNames from 'classnames';
import './Button.css';

type Props = {
  +children: string,
  +colorVariant: any,
  +className: string,
  +href: string,
}

const colorVariants = {
  BLACK: 'black',
  COLORED: 'colored',
};

const cls = suffix => (suffix ? `txo-btn-${suffix}` : `txo-btn`);

export const Button = ({children, colorVariant, className, href} : Props) => {
  const colorVariantClassName = {
    [colorVariants.BLACK]: cls('variant-black'),
    [colorVariants.COLORED]: cls('variant-colored'),
  }[colorVariant];

  return (
    <a
      href={href}
      target="_self"
      className={classNames(cls(), colorVariantClassName, className)}>
      {children}
      <span className={cls('arrow-right')}>$</span>
    </a>
  );
};

Button.defaultProps = {
  colorVariant: colorVariants.BLACK,
};
