/**
 * @Author: Martin Brejka <martin.brejka@technologystudio.sk>
 * @Date: 2019-08-12T14:08:11+02:00
 * @Copyright: Technology Studio
 * @flow
**/

import Layout from './Layout';

export default Layout;
